import React from "react";
import { X } from "lucide-react";

const DetailsDrawer = ({ sandwich, isOpen, onClose }) => {
  return (
    <div
      className={`fixed top-0 right-0 h-full w-full sm:w-3/4 md:w-1/3 bg-white shadow-lg z-50 transition-transform duration-300 ${
        isOpen ? "translate-x-0" : "translate-x-full"
      }`}
    >
      {/* Overlay */}
      <div
        className={`fixed inset-0 transition-opacity duration-300 ${
          isOpen ? "opacity-100 pointer-events-auto" : "opacity-0 pointer-events-none"
        }`}
        onClick={onClose}
      ></div>

      {/* Drawer Content */}
      <div className="relative h-full flex flex-col">
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
        >
          <X size={24} />
        </button>
        <div className="p-6">
          <h2 className="text-2xl font-bold mb-4 text-orange-600">{sandwich?.restaurant}: {sandwich?.name}</h2>
          <div className="flex flex-row">
            <div className="grow">
                <img
                    src={sandwich?.picture_url}
                    alt={sandwich?.name}
                    className="mt-4 max-w-full"
                />
            </div>
            <div className="grow">
                <img
                        src={sandwich?.restaurant_picture_url}
                        alt={sandwich?.name}
                        className="mt-4 max-w-full"
                    />
            </div>
          </div>

          <p className="mb-2 mt-8">
            {sandwich?.rating} / 5
          </p>

          <p>
          {sandwich?.review}
          </p>


        </div>
      </div>
    </div>
  );
};

export default DetailsDrawer;
