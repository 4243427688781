import './App.css';
import BreakfastSandwichTracker from './SandwichTracker';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <BreakfastSandwichTracker />
      </header>
    </div>
  );
}

export default App;
