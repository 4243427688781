import React, { useState, useEffect } from "react";
import axios from "axios";
import { Star, Loader2 } from "lucide-react";
import DetailsDrawer from './DetailsDrawer';

const BreakfastSandwichTracker = () => {
  const [allSandwiches, setAllSandwiches] = useState([]);
  const [filteredSandwiches, setFilteredSandwiches] = useState([]);
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [selectedSandwich, setSelectedSandwich] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [minRating, setMinRating] = useState(0);

  const selectSandwich = (sandwich) => {
    setDrawerOpen(true);
    setSelectedSandwich(sandwich);
  }

  const API_ENDPOINT = "https://2jnn8ehkd5.execute-api.us-east-1.amazonaws.com/";

  // Fetch sandwiches from API
  const fetchSandwiches = async () => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await axios.get(API_ENDPOINT, {
        params: { limit: 100 }, // Fetch a larger dataset to enable frontend filtering
      });

      const { sandwiches } = response.data;
      setAllSandwiches(sandwiches);
      setFilteredSandwiches(sandwiches); // Initialize filtered list
    } catch (err) {
      setError("Failed to fetch sandwiches. Please try again.");
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  // Handle filtering logic
  const handleFilterChange = () => {
    const filtered = allSandwiches.filter((sandwich) => {
      const matchesSearch =
        sandwich.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        sandwich.restaurant.toLowerCase().includes(searchTerm.toLowerCase());
      const matchesRating = sandwich.rating >= minRating;

      return matchesSearch && matchesRating;
    });
    setFilteredSandwiches(filtered);
  };

  // Initial load
  useEffect(() => {
    fetchSandwiches();
  }, []);

  // Re-filter sandwiches when searchTerm or minRating changes
  useEffect(() => {
    handleFilterChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm, minRating]);

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Loader2 className="animate-spin text-orange-600" size={48} />
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center p-4 bg-red-100 text-red-800">
        {error}
        <button
          onClick={fetchSandwiches}
          className="ml-4 px-4 py-2 bg-red-500 text-white rounded"
        >
          Retry
        </button>
      </div>
    );
  }

  return (
    <div className="max-w-8xl p-4">
      <header className="text-center mb-8">
        <h1 className="text-4xl font-bold text-orange-600">
          The Rhode Island Breakfast Sandwich Database
        </h1>
        <p className="text-gray-600"></p>
      </header>

      {/* Filters */}
      <div className="flex flex-col sm:flex-row items-center justify-between mb-4">
        <input
          type="text"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder="Search by name or restaurant"
          className="border border-gray-300 rounded p-2 w-full sm:w-1/2 mb-2 sm:mb-0"
        />
        <div className="flex items-center">
          <label htmlFor="rating" className="mr-2">
            Min Rating:
          </label>
          <select
            id="rating"
            value={minRating}
            onChange={(e) => setMinRating(Number(e.target.value))}
            className="border border-gray-300 rounded p-2"
          >
            <option value={0}>All</option>
            <option value={1}>1+</option>
            <option value={2}>2+</option>
            <option value={3}>3+</option>
            <option value={4}>4+</option>
            <option value={5}>5</option>
          </select>
        </div>
      </div>

      {/* Table */}
      <div className="overflow-x-auto">
        <table className="w-full bg-white shadow-md rounded-lg overflow-hidden text-sm">
          <thead className="bg-orange-100">
            <tr>
              <th className="p-2"></th>
              <th className="p-2 text-left">Sandwich</th>
              <th className="p-2 text-left hidden sm:table-cell">Restaurant</th>
              <th className="p-2 text-left">Rating</th>
              <th className="p-2 text-left hidden md:table-cell">Location</th>
              <th className="p-2 text-left hidden lg:table-cell"></th>
            </tr>
          </thead>
          <tbody>
            {filteredSandwiches.map((sandwich) => (
              <tr
                key={sandwich.id}
                className="border-b hover:bg-gray-50 transition"
                onClick={() => selectSandwich(sandwich)}
              >
                <td className="p-2">
                  <img
                    src={sandwich.picture_url}
                    alt={`${sandwich.name} from ${sandwich.restaurant}`}
                    className="max-w-20"
                  />
                </td>
                <td className="p-2 font-semibold text-orange-700 max-w-4">
                  {sandwich.name}
                </td>
                <td className="p-2 hidden sm:table-cell">
                  {sandwich.restaurant}
                </td>
                <td className="p-2">
                  <div className="flex items-center">
                    <Star size={18} className="text-yellow-500 mr-2" fill="currentColor" />
                    {sandwich.rating} / 5
                  </div>
                </td>
                <td className="p-2 hidden md:table-cell">
                  {sandwich.location}
                </td>
                <td className="p-2 hidden lg:table-cell italic text-gray-600">
                <button
                  onClick={() => selectSandwich(sandwich)}
                  className="px-4 py-2 text-blue"
                >
                  View Review
                </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {filteredSandwiches.length === 0 && (
        <div className="text-center p-4 text-gray-600">
          No sandwiches found. Try adjusting your search or filters.
        </div>
      )}

      <DetailsDrawer
          sandwich={selectedSandwich}
          isOpen={isDrawerOpen}
          onClose={() => setDrawerOpen(false)}
        />
    </div>
  );
};

export default BreakfastSandwichTracker;
